import React, { useState } from 'react';
import './Styles.css'
import Info from './Info.component.js'

function InfoMain(props) {

    let info = [];

    for (let key in props) {
        info.push(<Info key={key} {...props[key]} />);
    }
    return(
        <div className=''>
            {info}
        </div>
    );
}

export default InfoMain;
