import React, { useState } from 'react'
import { FcPhone, FcMenu } from 'react-icons/fc';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

import './Styles.css';

import ShowGallery from './ShowGallery.component.js'
import InfoMain from './InfoMain.component.js'
import Carousel from './Carousel.component.js'
import Mosiac from './Mosiac.component.js'


function App() {

 const tarrif = [ 
        {
        title: '',
        separator: '/images/1-page-div-brown.png',
        tagline: 'Make your holiday travel memorable at Himmatnagar with our suite for just Rs 5500/- + GST for a couple including all meals for a day.',
        img : {url: 'images/new/dowlat-villas-palace-room.jpeg'},
        txt : {str: 'Dowlat Villas Palace being a personal heritage palace property which is divided itself into Heritage Luxury Suites and Heritage Luxury rooms – (6). One can enjoy the royal heritage experience for budget prices at Rs 5500/- + GST for a couple, which includes all the meals. Extra Bed including meal is Rs 1500/- + GST, Free for kids upto 5 years of age', style: 'bullet'},
        lower_txt : 'All our heritage rooms are furnished with antique art-decor furniture and well equipped with all contemporary amenities facilities.'
        }
  ];

 const book = [ 
        {
        title: '~ Dowlat Villas Palace ~',
        separator: '/images/1-page-div-brown.png',
        tagline: 'Mahavirnagar, Dowlat Villas Palace Road, Himmatnagar – 383001, Gujarat, India',
        },
        {
        tagline : 'Call: +91-6355853389 / Email: dowlatvillaspalace@gmail.com / via Social Media, click Icons on top / Directions (Map Below)',
        img: {url: 'images/dowlat-villas-palace-banner-small-1.jpg'}
        },
  ];

 const facilities = [ 
        {
        title: '',
        separator: '/images/1-page-div-brown.png',
        tagline: 'Dowlat Villas Palace luxurious accommodation – Heritage Luxury Suites and Heritage Luxury Rooms are equipped with various room services such as:',
        img : {url: ''},
        txt : {str:'Art decor (vintage) bed, side table, dressing, cupboard, writing table, chair, sofa and so on|24-hour supply of hot and cold running water with attached bath|Electric Kettle and Purified Water|In-house laundry and dry cleaning facility (paid)|Free Wifi, Free Car Parking|Free Palace Tour|Interaction with members of the royal family', style:'points'},
        },
        {
        title: '',
        separator: '/images/1-page-div-brown.png',
        tagline: 'Dowlat Villas Palace provides various recreational activities and club room facilities –',
        img : {url: 'images/dowlat-villas-palace-pool-2.jpg'},
        txt : {str:'Swimming Pool|Area for outdoor sports such as cricket, badminton and football|Room with Pool Table, Table Tennis table, Carom,Various board games and Books|Common T.V lounge'},
        },
        {
        title: '',
        separator: '/images/1-page-div-brown.png',
        tagline: 'Other Facilities ( ONLY on prior request )',
        img : {url: 'images/new/dowlat-villas-palace-jeep.jpeg'},
        txt : {str:'Vintage Jeep Ride (paid)|Baby Cot (free)|Wheel Chair (free)|Barbecue Facility (free)'},
        lower_txt: 'Pet Friendly !!!',
        },
  ];

const events = [ 
        {
        title: 'Celebrations & Business Events',
        separator: '/images/1-page-div-brown.png',
        tagline: '',
        img : {url: ''},
        txt : {str:' Dowlat Villas palace  provides the perfect backdrop for short day trips. We believe in providing facilities for making your dreams come true for one’s respective occasion. A part from the formal events its a perfect backdrop for weekend getaways, holidays & informal picnics thanks to its vicinity to both Ahmedabad, Gujarat & Udaipur, Rajasthan being right on NH-8 highway. We host –'},
        },
        {
        title: '',
        separator: '',
        tagline: '',
        img : {url: 'images/dowlat-villas-palace-dining-1.jpg'},
        txt : {str:'Pre-wedding shoot, Catalogue shoot, Festive celebration, Birthday and Kitty parties.|Conferences, Corporate meals'}
        },
        {
        title: 'Meals',
        separator: '/images/1-page-div-brown.png',
        tagline: 'Choose any meal and pay ONLY for that particular meal !',
        img : {url: ''},
        txt : {str:'Breakfast|Complimentary High Tea|Dinner|* High Tea / Snacks / Sweets / Desserts will be limited by per person basis'},
        lower_txt: '',
        },
  ];




const about = [
        { 
        title: 'સ્વાગત છે ~ Welcome',
        separator: '/images/1-page-div-brown.png',
        tagline: 'A Royal Hotel & Home Stay' ,
        img : {url: 'images/new/dowlat-villas-palace-main_building.jpeg'},
        txt : '',
        lower_txt : '+91-6355853389 dowlatvillaspalace@gmail.com'
        },
        {
        title: '',
        separator: '/images/1-page-div-brown.png',
        img : {url: ''},
        tagline : ''
        },
        {
        title: '',
        separator: '/images/1-page-div-brown.png',
        img : {url: '/images/dowlat-villas-palace-drawing-room-1.jpg'},
        tagline : 'Dowlat Villas Palace, a heritage hotel and a homestay property is located at Himmatnagar, Gujarat, India at an hours drive from Ahmedabad and a little bit more from Udaipur on NH-8 highway between Ahmedabad,Gujarat-Udaipur, Rajasthan.'
        },
];

const main = [ 
        {
        title: 'સ્વાગત છે ~ Welcome',
        separator: '/images/1-page-div-brown.png',
        tagline: 'A Royal Hotel & Home Stay' ,
        img : {url: 'images/new/dowlat-villas-palace-main_building.jpeg'},
        txt : '',
        lower_txt : '+91-6355853389 dowlatvillaspalace@gmail.com'
        }
  ];

// Carousel
const carousel_1 = [ 
        {
        title: 'A Regal Stay',
        separator: '',
        tagline : 'Dowlat Villas Palace being a personal', 
        img : {url: 'images/new/dowlat-villas-palace-room.jpeg'},
        txt: { 'str' : 'Make your holiday travel memorable at Himmatnagar with our suite for just Rs 5500/- + GST for a couple including all meals for a day.'},
        }, 
        {
        tagline: '',
        title: 'Tantalizing treats',
        separator: '',
        img : {url: 'images/new/dowlat-villas-palace-food.jpeg'},
        txt: { str: 'Indulge in a culinary journey like no other at our hotel\'s exquisite dining spread. From tantalizing appetizers to mouthwatering main courses and delectable desserts.'},
        },
        {
        title: 'Celebrations & Business Events',
        tagline: '',
        img : {url: 'images/new/dowlat-villas-palace-swimming_pool.jpeg'},
        txt : {str:' Dowlat Villas palace caters for all personal and corporate events and  provides the perfect setup for your events and celebrations to mark your memories.'},
        },
  ];

const mosiac_2 = [ 
        {
        title: 'Background',
        separator: '',
        tagline: '',
        img : {url: 'images/dowlat-villas-palace-drawing-room-u-1.jpg'},
        txt : {str: 'Maharaj Shri Man Singh visioned & made Dowlat Villas Palace in a shape of a scorpion. He was the second son of H.H.The Maharaja Dowlat Singhji Saheb of Idar.  Built in late 1920s -1930s,The palace consists of the central portion which included the face, body and sting of the scorpion. Shri Mann Singhji Saheb abdicated and made his eldest son Maharaj Umeg Singhji as the Maharaj Shri Saheb of Jaswantgadh-Idar in 5th April 1948.  Presently, Maharaj Shri Bhagirath Singh – eldest son of Maharaj Shri Umeg Singhji with his son Rajkumar Shri Suryavir Singhji with his family reside here. Rajkumar Shri Suryavir Singhji after his Symbiosis MBA and successful corporate career converted the palace property into Heritage Homestay and opened it for public.'},
        }, 
        ];

 const mosiac_3 = [ 
        {
        title: 'Yes we love to travel ! especially with our four legged companions...',
        separator: '',
        tagline: '',
        //img : {url: 'images/new/dowlat-villas-palace-organic-garden-vertical.jpeg'},
        img : {url: 'images/design/pet-friendly-1.png'},
        txt : {str: 'We love our four legged companions and so Dowlat Villas Palace, is a Pet friendly place. In addtion we love outdoors, so call us for nearby excursions.'},
        img1 : {url: 'images/new/dowlat-villas-palace-pet_friendly-vertical.jpeg'},
        }, 
        ];

const mosiac_4 = [ 
        {
        title: 'Architecture',
        separator: '',
        tagline: '',
        img : {url: 'images/dowlat-villas-palace-main-1.jpg'},
        txt : {str: 'A symbol of opulence and class, the breathtaking royal residence represented by Dowlat Villas Palace is an unique castle like structure embodied with superior stone structure - round archways, and barrel-vaulted ceilings intreicated with sophisticated and creative techniques. It includes bailey and enceinte, keep, gatehouse, curtain wall, battlements, arrowslits and great hall.  The exteriors is marked with massive vertical gold painted pillars, open and closed galleries, marble flooring, statues etc. A series of thicker double-walled rings were erected to surround the whole inner structure.  The principal architect of furniture was John Roberts—filling the space with some of the time’s finest original furnishings and early 19th-century artwork.'} 
        }, 
        ];

const footer_1 = [ 
        {
        title: 'A few things to note ...',
        separator: '/images/1-page-div-brown.png',
        tagline: '' ,
        img : {url: ''},
        txt : {str: 'Timings, Breakfast: 8:30am ~ 10:00am. Lunch: 1:30pm ~ 3:00pm, High Tea: 5pm,  Dinner: 8:30pm ~ 10pm. | We believe in serving FRESH home cooked food.  Menu is fixed. No À la carte. Menu may be changed last minute due to unforeseen circumstances or on request, but only on prior notice but depends on management\'s discretion, Sweet dish (limited for all meals) | Complementary High Tea with only ~ AP-Plan ~ (limited pieces , 1 per person) | Rooms... only 2 persons + 1 extra bed allowed' }
        }, 
        ];


  function getAboutExtendedInfo() {

    const showAbout       = <InfoMain {...about} />;
    const showCarousel_1  = <Carousel {...{'data':carousel_1}} />;
    const showMosiac_2  = <Mosiac {...{'data':mosiac_2, 'type' : 'image_text'}} />;
    const showMosiac_3  = <Mosiac {...{'data':mosiac_3, 'type' : 'image_text_image'}} />;
    const showMosiac_4  = <Mosiac {...{'data':mosiac_4, 'type' : 'text_image'}} />;

    const showFooter_1  = <InfoMain {...footer_1} />;

    return (
    <div>
        {showAbout}
        <div className='info'> <img className='imgsmall' alt='' src='/images/1-page-div-brown.png' /> </div>
        {showCarousel_1}
        <div className='info'> <img className='imgsmall' alt='' src='/images/1-page-div-brown.png' /> </div>

        {showMosiac_2}
        <div className='info'> <img className='imgsmall' alt='' src='/images/1-page-div-brown.png' /> </div>
        {showMosiac_3}
        <div className='info'> <img className='imgsmall' alt='' src='/images/1-page-div-brown.png' /> </div>
        {showMosiac_4}
        <div className='info'> <img className='imgsmall' alt='' src='' /> </div>

        <div class='desctxt'>
            {showFooter_1}
        </div>
        <div className='info'> <img className='imgsmall' alt='' src='/images/1-page-div-brown.png' /> </div>
        <div className='icons_page'>
            <div className='info'>
                <a href='tel:+91-6355-853-389'><FcPhone />+91-6355-853-389</a>
            </div>
            <div className='info'>

                <a href='http://www.facebook.com/dowlatvillas'><FaFacebook /></a>
                <a href='http://instagram.com/dowlatvillaspalace'><FaInstagram /></a>
                <a href='http://twitter.com/DowlatVPalace'><FaTwitter /></a>
            </div>
        </div>
        </div>
    );

  }


  const showAbout       = getAboutExtendedInfo();
  const showBook        = <InfoMain {...book} />;
  const showEvents      = <InfoMain {...events} />;
  const showGallery     = <ShowGallery  />;
  const showFacilities  = <InfoMain {...facilities} />;
  //const showMain       = <InfoMain {...main} />;
  const showTarrif      = <InfoMain {...tarrif} />;
  
  const [select, setSelect] = useState(0);
  const [menu, setMenu] = useState(0);

  let menuSel = {
    about   : { color: 'white', func: showAbout},
    book    : { color: 'white', func: showBook },
    events  : { color: 'white', func: showEvents },
    facilities  : { color: 'white', func: showFacilities},
    shop    : { color: 'white'},
    media   : { color: 'white', func: showGallery},
    tarrif  : { color: 'white', func: showTarrif},
  };

  let show = showAbout;
  if (menuSel[select]) {
      menuSel[select].color = '#F0F0F0';
      if (select) {
        show = menuSel[select].func;
      }
  }

  let showMap = (select === 'book' || select === 'about') ?  
    <p className='map'>
        <img alt='' src='/images/1-page-div-brown.png' />
            <iframe src='https://www.google.com/maps/d/u/1/embed?mid=1xwmMnAJTtityhlWUGGqGuqD3UaY'>
        </iframe>
        <img alt='' src='/images/1-page-div-brown.png' />
    </p> : '';

  return (

    <div className='App'>
        <div className='icons'>
                <a href='http://www.facebook.com/dowlatvillas'><FaFacebook /></a>
                <a href='http://instagram.com/dowlatvillaspalace'><FaInstagram /></a>
                <a href='http://twitter.com/DowlatVPalace'><FaTwitter /></a>
        </div>
        <div className='heading'>
            <img className='small' src='images/dowlat-villas-palace-logo.jpg' />
            <h1>Dowlat Villas Palace</h1>
            <div className='small'>
                 Himmatnagar, Gujarat
            </div>
            <div className='small'>
                <a href='tel:+91-6355-853-389'><FcPhone />+91-6355-853-389</a>
            </div>
        </div>
                <div className='pulldown'>
            <button onClick={() => (menu === 'block')?setMenu('none'):setMenu('block')}>Menu<FcMenu /></button>
        </div>

        <div className='menu' style={{display: `${menu}`}}>
            <button style={{background: `${menuSel.about.color}`}} onClick={() => setSelect('about')}>History</button>
            <button onClick={() => setSelect('facilities')}>Facilities</button>
            <button style={{background: `${menuSel.events.color}`}} onClick={() => setSelect('events')}>Events</button>
            <button style={{background: `${menuSel.tarrif.color}`}} onClick={() => setSelect('tarrif')}>Tarrif</button>
            <button style={{background: `${menuSel.book.color}`}} onClick={() => setSelect('book')}>Booking</button>
            <button style={{background: `${menuSel.media.color}`}} onClick={() => setSelect('media')}>Press & Media</button>
            <a className='menu.button' target='_blank' href='https://colorsofcraft.com'>Shop</a>
        </div>
        {show}
       {showMap}
    </div>
  );
}

export default App;
