import './Styles.css';

function Mosiac(props) {

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

    function MosiacItem(props) {
        if (props.type === 'image_text') {
            let item = props.data[0];
            return (
                <div className=''>
                    <img className='imgmedium mosiaclarge' alt='' src={item.img.url} />
                    <div className='mosiactext'>
                        <div className='infotitle'> {item.title} </div>
                        <img className='imgmedium border_disable' alt='' src='images/design/line-dev-6.png' />
                        <div className='desctxt'> {item.txt.str} </div>
                    </div>
                </div>
             );
         }

        if (props.type === 'text_image') {
            let item = props.data[0];
            return (
                <div>
                    <div className='mosiactext'>
                        <div className='infotitle'> {item.title} </div>
                        <img className='imgmedium border_disable' alt='' src='images/design/line-dev-6.png' />
                        <div className='desctxt'> {item.txt.str} </div>
                    </div>
                    <img className='imgmedium mosiaclarge' alt='' src={item.img.url} />
                </div>
             );
         }
 
        if (props.type === 'image_text_image') {
            let item = props.data[0];
            return (
                <div className='mosiac_wrapper labelbg'>
                    <div className='mosiac_item'>
                        <img className='imgmedium' alt='' src={item.img.url} />
                    </div>
                    <div className='mosiactext'>
                        <div className='infotitle'> {item.title} </div>
                        <div className='desctxt'> {item.txt.str} </div>
                    </div>
                    <div className='mosiac_item'>
                        <img className='imgmedium' alt='' src={item.img1.url} />
                    </div>
                </div>
             );
         }

    }

    let showMosiac = MosiacItem(props);


    return showMosiac;
}

export default Mosiac;
