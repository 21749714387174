import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import './Styles.css';

function CarouselCom(props) {

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

    function CarouselItem(items) {
        let ren_items = [];
        for (let indx in items) {
            let item = items[indx]; 
            ren_items.push(
                <div className='info' key={indx}>
                    <img className='imgmedium' alt='' src={item.img.url} />
                    <div className='labelbg'>
                        <div className='infotitle'> {item.title} </div>
                        <img className='imgmedium border_disable' alt='' src='images/design/line-div-3.png' />
                        <div className='desctxt'> {item.txt.str} </div>
                    </div>
                </div>
            );
        }
        return (ren_items);
    }

    let showCarousel = CarouselItem(props.data);


return (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={props.deviceType !== "mobile" ? true : false}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={2000}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
      deviceType={props.deviceType}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-10-px"
    >
    {showCarousel}
    </Carousel>
    );
}

export default CarouselCom;
