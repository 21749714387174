import React, { useState } from 'react';
import './Styles.css'

function Info(props) {

    let txt  = props?.txt?.str?.split('|');
    let strs = [];
    
    if (txt?.length) {
       for (let i=0; i < txt.length; ++i) {
            strs.push(<div className='infotxt' key={'s_' + i}>{txt[i]}</div>);
       }
    }

    const orig = props?.txt;
    const [fullTxt, setFullTxt] = useState(props?.txt, 0);
    const [less, setLess]       = useState(true, 0);

    let readmore = {};

    if (fullTxt?.length > 255 && less) {
        setFullTxt(fullTxt.substring(0,255));
        readmore = <button className='readmore' onClick={expand}>Read More</button>;
    }

    let expand = () => {
        
        setLess(false)
        setFullTxt(orig);
    }

    const mainImg = (props?.img?.url) ?  <img className='imgmain' alt='' src={props?.img?.url} /> : '';

    return(
        <div className='info'>
            <div className='title'>
                {props?.title}
            </div>

            <div className='tagline'>
                {props?.tagline}
            </div>

            <div>
                <img className='imgsmall' alt='' src={props?.separator} />
            </div>

            <div className='infotxt'>
                {strs} 
            </div>
           
           {mainImg}

            <div className='desctxt'>
                {props?.lower_txt}
            </div>
        </div>
        );
}

export default Info;
