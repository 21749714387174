export const photos = [
  {
    src: 'images/media/dowlat-villas-palace-media-femina-1.jpg',
    width: 1,
    height: 1
  },
  {
    src: 'images/media/dowlat-villas-palace-media-living-magazine-1.jpg',
    width: 3,
    height: 4
  },
  {
    src: 'images/media/dowlat-villas-palace-media-living-magazine-2.jpg',
    width: 1,
    height: 1
  },
  {
    src: 'images/media/dowlat-villas-palace-media-living-magazine-3.jpg',
    width: 4,
    height: 3
  },
  {
    src: 'images/media/dowlat-villas-palace-media-family-tree-1.jpg',
    width: 1,
    height: 1
  },
  {
    src: 'images/media/dowlat-villas-palace-media-family-tree-2.jpg',
    width: 1,
    height: 1
  },
  {
    src: 'images/media/dowlat-villas-palace-media-family-tree-3.jpg',
    width: 1,
    height: 1
  },  
  {
    src: 'images/media/dowlat-villas-palace-media-family-tree-4.jpg',
    width: 1,
    height: 1
  },
  {
    src: 'images/media/dowlat-villas-palace-media-family-tree-5.jpg',
    width: 1,
    height: 1
  }
];
